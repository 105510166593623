import revive_payload_client_4sVQNw7RlN from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_1v2zamqxGl from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/.nuxt/vue-history-state/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import history_state_pVV6k7o9qp from "/home/tsukamoto/public_html/workspace/nuxt3-client-srv/plugins/history-state.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_1v2zamqxGl,
  chunk_reload_client_UciE0i6zes,
  history_state_pVV6k7o9qp
]